// 1. Your custom variables and variable overwrites.
//
// Colors
//

$global-font-family: 'Mulish', sans-serif;
$global-color: #ffffff;
$global-emphasis-color: #daa520;
$global-primary-color: #0E0617;
$global-muted-color: #6e6e74;

$global-link-color: #daa520;
$global-link-hover-color: #2269cc;

$global-border: #0b0f33;

//
// Backgrounds
//

$global-background: #6E28AE;

$global-muted-background: #0E0617ee;
$global-primary-background: #00000063;
$global-secondary-background: #0E0617;

$global-success-background: #36a330;
$global-warning-background: #daa520;
$global-danger-background: #bd3838;

//
// Fonts
//
$base-heading-font-family: 'Bai Jamjuree', sans-serif;

// 2. Import default variables and available mixins.
@import "uikit/src/scss/variables-theme.scss";
@import "uikit/src/scss/mixins-theme.scss";
@import url('https://fonts.googleapis.com/css2?family=Bai+Jamjuree&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mulish&display=swap');

// 3. Your custom mixin overwrites.
@mixin hook-button() { border-radius: 120px; font-family: 'Bai Jamjuree', sans-serif; }
@mixin hook-card() { border-radius: 12px; }
@mixin hook-navbar() { box-shadow: 0 6px 12px #0E0617; }

// 4. Import UIkit.
@import "uikit/src/scss/uikit-theme.scss";


// CSS overrides
html { min-width: 330px; }
header { position: fixed; width: 100%; height: 80px; top: 0; left: 0; z-index: 1000; }
section:not(#intro) div :is(img, video) { border-radius: 12px; }

section:not(#intro) .uk-cover-container {
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
}

.gradient {
    background-image: radial-gradient(at top, #6E28AE 0 15%, #0E0617 95% 100%);
}

.enlarge { font-size: clamp(1rem, 0.3143rem + 1.7143vw, 1.5rem); }

.border {border: solid 1.26px #daa520!important;}

.scroll-down {
    animation: bounce 1.5s infinite;
    transform: rotate(90deg);
}

.map {
    background-image: url(../static/roadmap-bg.jpg?as=webp&width=1920);
    background-repeat: no-repeat;
    background-size: cover;
}

.uk-overlay-default {
    background: #00000063;
}

.uk-offcanvas-bar .uk-nav-primary > li > a, .uk-offcanvas-bar .uk-nav-primary .uk-nav-sub a, .uk-offcanvas-bar .uk-nav-primary > li.uk-active > a, .uk-offcanvas-bar .uk-nav-primary .uk-nav-header {
    color: $global-primary-background;
    &:hover {
        color: $global-link-color;
    }
}

.uk-card-default {
    background-color: #00000063;
}

.uk-button-primary {
    background-color: #00000000;
    border: solid 1.26px #daa520!important;
    transition: all .7s;
    &:hover {
        background-color: $global-primary-color;
        box-shadow: 0 4px 8.52px #00000063;
    }
}

.uk-accordion-title {
    background-color: #6E28AE63;
    border-radius: 12px;
    color: #daa520;
    font-size: 1.25rem;
    line-height: 1.4;
    display: block;
    overflow: hidden;
    padding: 15px 30px;
}

.uk-accordion-content {
    padding: 30px 15px;
}

.uk-input:focus, .uk-select:focus, .uk-textarea:focus {
    color: #111111;
    background-color: transparent;
    border-color: #0e0617;
    outline: none;
}

@keyframes bounce {
    0% {
      transform: rotate(90deg) translatex(0);
    }
    50% {
      transform: rotate(90deg) translatex(20px);
    }
    100% {
      transform: rotate(90deg) translatex(0);
    }
}

@media only screen and (min-width : 960px) {
    section:not(#intro) .uk-cover-container {
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;
        border-top-left-radius: 0;
    }
}

@media only screen and (max-width : 462px) {
    .glow {box-shadow: 0 18px 12px -20px $global-emphasis-color, 0 24px 28px -24px $global-emphasis-color;}
}